<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevoPaciente" />
                        <Button label="Actualizar" icon="pi pi-refresh" class="mr-2" severity="secondary" @click="Inicializador" />
                    </template>
                </Toolbar>
                <DataTable ref="dtPacientes" 
                    lazy
                    v-model:expandedRows="expandedRows" 
                    :value="oListaPacientes"
                    dataKey="PacienteId"
                    :rowHover="true"
                    :paginator="true" 
                    :rows="filasPorPagina"
                    :totalRecords="totalFilas"
                    :rowsPerPageOptions="[10, 25, 50, 100]"
                    :loading="bCargando"
                    :filters="filtros"
                    @page="CambiarPagina"
                    class="p-datatable-sm"
                    >

                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <h5 class="m-0">Pacientes</h5>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <InputText v-model="filtros['global'].value" placeholder="Buscar..." @input="BuscarPaciente" />
                            </span>
                        </div>
                    </template>
                    <Column expander style="width: 5rem" />
                    <Column field="Persona.NumeroDocumento" header="D.N.I." :sortable="true"></Column>
                    <Column field="Persona.NombreCompleto" header="Paciente" :sortable="true">
                        <template #body="slotProps">
                            <b>{{ slotProps.data.Persona.NombreCompleto }}</b><br/>
                            {{ slotProps.data.CodigoPaciente }}
                        </template>
                    </Column>
                    <Column field="Estado" header="Estado" :sortable="true">
                        <template #body="slotProps">
                            <div class="centered-content">
                                <Tag v-if="slotProps.data.Estado" value="Activo" severity="success" />
                                <Tag v-if="!slotProps.data.Estado" value="Inactivo" severity="danger" />
                            </div>
                        </template>
                    </Column>
                    <Column style="text-align: center;" headerStyle="min-width:12rem;">
                        <template #body="slotProps">
                            <SplitButton label="Acciones" icon="pi pi-check" menuButtonIcon="pi pi-cog"  outlined severity="secondary" :model="slotProps.data.ListaAcciones" />
                        </template>
                    </Column> 

                    <template #expansion="slotProps">
                        <div class="p-4" v-if="slotProps.data.ListaFichas.length > 0">
                            <p class="font-bold"><i class="pi pi-heart"></i> Fichas de Atención</p>
                            <DataTable :value="slotProps.data.ListaFichas">
                                <Column field="CodigoFichaAtencion" header="Código"></Column>
                                <Column field="NombreProducto" header="Paquete"></Column>
                                <Column field="NombreCategoria" header="Categoria"></Column>
                                <Column field="PrecioFinal" header="Precio">
                                    <template #body="slotProps">
                                        S/.{{ Utilitario.formatearDecimal(slotProps.data.PrecioFinal) }}
                                    </template>
                                </Column>
                                <Column field="FechaInicio" header="Fecha Inicio">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.FechaInicio, "dd/MM/yyyy") }}
                                    </template>
                                </Column>
                                <Column field="FechaFin" header="Fecha Fin">
                                    <template #body="slotProps">
                                        {{ Utilitario.formatoFecha(slotProps.data.FechaFin, "dd/MM/yyyy") }}
                                    </template>
                                </Column>
                                <Column field="NombreEstado" header="Estado">
                                    <template #body="slotProps">
                                        <div class="centered-content">
                                            <Tag v-if="slotProps.data.EstadoFichaId == 3" :value="slotProps.data.NombreEstado" severity="success" />
                                            <Tag v-if="slotProps.data.EstadoFichaId == 2" :value="slotProps.data.NombreEstado" severity="info" />
                                            <Tag v-if="slotProps.data.EstadoFichaId == 1" :value="slotProps.data.NombreEstado" severity="secondary" />
                                        </div>
                                    </template>
                                </Column>
                                <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-folder-open" v-tooltip.top="'Ver Ficha de Atención'" class="p-button-rounded mr-2 p-button-success" outlined 
                                            @click="AbrirFicha(slotProps.data.PacienteId, slotProps.data.ProductoId, slotProps.data.FichaAtencionId)" />
                                        <Button icon="pi pi-calendar-plus" v-tooltip.top="'Citas'" class="p-button-rounded mr-2 p-button-info" outlined 
                                            @click="CrearCita(slotProps.data.FichaAtencionId)" />
                                        <Button icon="pi pi-apple" v-tooltip.top="'Dietas'" class="p-button-rounded mr-2 p-button-warning" outlined
                                            @click="CrearDieta(slotProps.data.FichaAtencionId)" />
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                </DataTable>
            </div>
        </div>
    </div>
</template>

<script setup>
import { FilterMatchMode } from 'primevue/api';
import { onBeforeMount, onMounted, ref } from 'vue';
import PacienteService from '@/service/PacienteService';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useConfirm } from 'primevue/useconfirm';
import Crypto from '@/utilitarios/Crypto';
import { useToast } from 'primevue/usetoast';
import Utilitario from '@/utilitarios/Utilitario';

//#region Variables
const router = useRouter();
const store = useStore();
const confirm = useConfirm();
const toast = useToast();

const pacienteService = new PacienteService();
const usuarioStore = JSON.parse(store.state.datauser);
const oListaPacientes = ref(null);

const bCargando = ref(false);
const filtros = ref({});

const paginaActual = ref(0);
const filasPorPagina = ref(10);
const totalFilas = ref(0);

const expandedRows = ref({});
let debounceTimer;
//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
});

onBeforeMount(() => {
    InicializarFiltros();
});
//#endregion

//#region Metodos
const Inicializador = () => {
    filtros.value.global.value = '';
    ListarPacientes();
}

const InicializarFiltros = () => {
    filtros.value = {
        global: { value: null, matchMode: FilterMatchMode.CONSTAINS }
    };
};

const ListarPacientes = async() => {
    try{
        bCargando.value = true;
        const request = {
            Pagina : paginaActual.value,
            TotalFilas : filasPorPagina.value,
            TextoBusqueda : filtros.value.global.value || ""
        };
        
        const response = await pacienteService.ListarPacientesService(request).then();
        if(response.Status == 200){
            const oListaResponse = [];
            if(response.Data != null && response.Data != undefined){
                oListaResponse.value = response.Data.map(fila => (
                                    {
                                        ...fila, 
                                        Persona: { 
                                            ...fila.Persona, 
                                            PersonaId: Crypto.Encriptar(fila.Persona.PersonaId)
                                        },
                                        ListaAcciones: [
                                            {
                                                label: 'Nueva Ficha',
                                                icon: 'pi pi-plus',
                                                command: () => {
                                                    const id = encodeURIComponent(Crypto.Encriptar(fila.PacienteId));
                                                    let linkNuevo = router.resolve({ path: `/portal/fichaatencionseleccion/${id}` });
                                                    window.open(linkNuevo.href, '_blank');
                                                }
                                            },
                                            {
                                                label: 'Ver Fichas',
                                                icon: 'pi pi-heart',
                                                command: () => {
                                                    toast.add({ severity: 'warn', summary: 'Delete', detail: 'Data Deleted', life: 3000 });
                                                }
                                            },
                                            {
                                                separator: true,
                                            },
                                            {
                                                label: 'Editar Paciente',
                                                icon: 'pi pi-pencil',
                                                command: () => {
                                                    const id = encodeURIComponent(Crypto.Encriptar(fila.Persona.PersonaId));
                                                    let linkEditar = router.resolve({ path: `/portal/nuevopaciente/${id}` });
                                                    window.open(linkEditar.href, '_blank');
                                                }
                                            },
                                            {
                                                label: (fila.Estado ? 'Desactivar' : 'Activar') + ' Paciente',
                                                icon: fila.Estado ? 'pi pi-minus-circle' : 'pi pi-power-off',
                                                command: () => {
                                                    ActivarDesactivarPaciente(fila.PacienteId, fila.CodigoPaciente, fila.Estado)
                                                }
                                            }
                                        ]
                                    }
                                ));

            }
            oListaPacientes.value = oListaResponse.value;
            totalFilas.value = response.Count;
        }
    }
    catch (error) {
        console.error('Error al listar pacientes:', error);
    } finally {
        bCargando.value = false;
    }    
}

const CambiarPagina = (event) => {
    paginaActual.value = event.page;
    filasPorPagina.value = event.rows;
    ListarPacientes();
}

const BuscarPaciente = async() => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(async () => {
        await ListarPacientes();
    }, 300);
}

const NuevoPaciente = () => {
    router.push('/portal/nuevopaciente');
}

const ActivarDesactivarPaciente = (parametro, codigoPaciente, estado) => {
    const nombreEstado = estado ? 'Desactivar' : 'Activar';
    const nombreEstadoConfirmacion = estado ? 'desactivó' : 'activó';
    confirm.require({
        message: `¿Está seguro de ${nombreEstado} el paciente ${codigoPaciente}?`,
        header: 'Confirmación',
        icon: 'pi pi-info-circle',
        rejectLabel: 'No',
        acceptLabel: 'Si',
        accept: async () => {
            bCargando.value = true;
             try{
                const request = {
                    PacienteId: parametro,
                    UsuarioCreacion: usuarioStore.User.UsuarioId
                };

                const response = await pacienteService.ActivarDesactivarPacienteService(request).then();
                if(response.Status == 201){
                    toast.add({ severity: 'success', summary: 'Confirmación', detail: `Se ${nombreEstadoConfirmacion} el paciente ${codigoPaciente} correctamente`, life: 6000});
                    ListarPacientes();
                }
            } catch (error) {
                bCargando.value = false;
                console.error('Error al '+nombreEstado+' el paciente:', error);
            }
        }
    });
}

const AbrirFicha = (pacienteId, productoId, fichaId) => {
    const pacienteIdUrl = encodeURIComponent(Crypto.Encriptar(pacienteId));
    const productoIdUrl = encodeURIComponent(Crypto.Encriptar(productoId));
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/fichaatencion/${pacienteIdUrl}/${productoIdUrl}/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}

const CrearCita = (fichaId) => {
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/citaatencion/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}

const CrearDieta = (fichaId) => {
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/dietaatencion/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}
//#endregion
</script>

<style scoped lang="scss">
.centered-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ajusta según sea necesario */
}
</style>


