import { createRouter, createWebHashHistory } from 'vue-router'

import PasswordChangeView from '../views/PasswordChangeView'
import DashboardView from '../views/DashboardView.vue'
import PageNotFoundView from '../views/PageNotFoundView'
import InitView from '../views/InitView'
import AppLayout from '@/layout/AppLayout.vue';
import LoginView from '../views/LoginView';
import LandingView from '../views/LandingView';
import ModulesView from '../views/ModulesView';
import MantenedorUsuario from '../components/Usuario/MantenedorUsuario'

import { useStore } from "vuex";
import NuevoPaciente from '@/components/Paciente/NuevoPaciente.vue'
import MantenedorPaciente from '@/components/Paciente/MantenedorPaciente.vue'
import SeleccionProductoTipo from '@/components/Producto/SeleccionProductoTipo.vue'
import MantenedorProducto from '@/components/Producto/MantenedorProducto.vue'
import NuevoProducto from '@/components/Producto/NuevoProducto.vue'
import FichaAtencion from '@/components/FichaAtencion/FichaAtencion.vue'
import SeleccionProductoFicha from '@/components/FichaAtencion/SeleccionProductoFicha.vue'
import CitaAtencion from '@/components/Cita/CitaAtencion.vue'
import DietaAtencion from '@/components/Dieta/DietaAtencion.vue'
import PagoAtencion from '@/components/Pago/PagoAtencion.vue'
import AperturaCierreCaja from '@/components/Caja/AperturaCierreCaja.vue'
import MovimientoDiarioCaja from '@/components/Caja/MovimientoDiarioCaja.vue'
import MantenedorCategoria from '@/components/Categoria/MantenedorCategoria.vue'

const routes = [{
        path: '/web',
        component: LandingView
    },
    {
        path: '/',
        component: LoginView,
        name: 'Login'
    },
    {
        path: '/portal',
        component: AppLayout,
        children: [
          {
                path: '/portal',
                component: InitView,
                name: "Init",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system-dashboard',
                component: DashboardView,
                name: "Dashboard",
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
                path: '/system/modules',
                component: ModulesView,
                meta: {
                    requiresAuthentication: true,
                }
            },            
            {
                path: '/system/change-password',
                component: PasswordChangeView,
                meta: {
                    requiresAuthentication: true,
                }
            },
            {
              path: '/portal/usuario',
              component: MantenedorUsuario,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/categoria',
              component: MantenedorCategoria,
              meta: {
                requiresAuthentication: true
              }
            },            
            {
              path: '/portal/paciente',
              component: MantenedorPaciente,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/nuevopaciente/:id?',
              component: NuevoPaciente,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/producto',
              component: SeleccionProductoTipo,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/mantenedorproducto/:id',
              component: MantenedorProducto,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/nuevoproducto/:tipoid/:id?',
              component: NuevoProducto,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/fichaatencion/:pacienteid/:productoid/:id?',
              component: FichaAtencion,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/fichaatencionseleccion/:pacienteid',
              component: SeleccionProductoFicha,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/citaatencion/:fichaid/:citaid?',
              component: CitaAtencion,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/dietaatencion/:fichaid/:dietaid?',
              component: DietaAtencion,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/pagoatencion/:fichaid/:pagoid?',
              component: PagoAtencion,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/aperturacierrecaja',
              component: AperturaCierreCaja,
              meta: {
                requiresAuthentication: true
              }
            },
            {
              path: '/portal/movimientodiariocaja',
              component: MovimientoDiarioCaja,
              meta: {
                requiresAuthentication: true
              }
            },
            {
                path: "*",
                component: PageNotFoundView,
                // meta: {
                //   requiresAuthentication: true,
                // }
            }
        ]
    }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL), 
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  let store = useStore();
  let dUser = JSON.parse(store.getters["isAuthenticated"]);
  const isAuthenticated = dUser;
  if (from.name !== "Login") {
    if (to.matched.some((record) => record.meta.requiresAuthentication)) {
      if (isAuthenticated) {
        next();
      } else {
        next({ name: "Login" });
      }
    } else {
      if (isAuthenticated && to.meta.disallowAuthed) {
        next({ name: "Init" });
      }
      next();
    }
  } else {
    next();
  }
});

export default router;
