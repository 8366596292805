<template>
    <div class="grid">
        <div class="col-12">
            <div class="card">
                
                <!-- Spinner de carga -->
                <div v-if="bCargandoInicial" class="progress-spinner-custom">
                    <div class="spinner">
                        <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                    </div>
                </div>
                <Toast />
                <ConfirmDialog></ConfirmDialog>
                <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                    <template #start>
                        <h1>{{ parametro ? 'Editar' : 'Nuevo'}} Paciente</h1>
                    </template>

                    <template #end>
                        <Button label="Atrás" icon="pi pi-arrow-left" severity="secondary" @click="IrAtras"></Button>
                    </template>
                </Toolbar>
                <Stepper :linear="!parametro">
                    <StepperPanel header="Datos Personales">
                        <template #content="{ nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                    
                                    <div class="p-fluid p-formgrid grid m-5">
                                        <div class="field col-12 md:col-6" v-if="parametro">
                                            <FloatLabel>
                                                <InputText id="txtPersonaId" v-model="oPersona.PersonaId" type="hidden" />
                                                <InputText id="txtCodigoPaciente" v-model="oPaciente.CodigoPaciente" autocomplete="off" readonly v-tooltip.top="oPaciente.CodigoPaciente ? '' : 'El Código de Paciente se generará cuando llene las Condiciones Médicas'" />
                                                <label for="lblCodigoPaciente">Código Paciente</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6" v-if="parametro">
                                            <Checkbox id="chkEstado" v-model="oPaciente.Estado" :binary="true" />
                                            <label for="lblEstado" class="ml-2 label-check"> Activo </label>
                                        </div>                                        
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <InputText v-model="oPersona.PrimerNombre" autocomplete="off"
                                                    :class="{ 'p-invalid' : primerNombreInvalido }" />
                                                <label for="lblPrimerNombre">Primer Nombre</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <InputText v-model="oPersona.SegundoNombre" autocomplete="off" />
                                                <label for="lblSegundoNombre">Segundo Nombre</label>
                                            </FloatLabel>
                                        </div>

                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <InputText v-model="oPersona.ApellidoPaterno" autocomplete="off" 
                                                    :class="{ 'p-invalid' : apellidoPaternoInvalido }" />
                                                <label for="lblApellidoPaterno">Apellido Paterno</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <InputText v-model="oPersona.ApellidoMaterno" autocomplete="off"
                                                    :class="{ 'p-invalid' : apellidoMaternoInvalido }" />
                                                <label for="lblApellidoMaterno">Apellido Materno</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <Dropdown v-model="oPersona.TipoDocumento" :options="oListaTipoDocumento" 
                                                    optionLabel="Nombre" optionValue="TipoDocumentoId" 
                                                    placeholder="Tipo Documento" class="w-full" 
                                                    :class="{ 'p-invalid' : tipoDocumentoInvalido }" />
                                                <label for="lblTipoDocumento">Tipo Documento</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <InputText v-model="oPersona.NumeroDocumento" autocomplete="off" :maxlength="ObtenerMaximoCaracteres()"
                                                :class="{ 'p-invalid' : numeroDocumentoInvalido }" />
                                                <label for="lblNumeroDocumento">Número Documento</label>
                                            </FloatLabel>
                                        </div>

                                        <div class="field col-12 md:col-4">
                                            <FloatLabel>
                                                <Calendar v-model="oPersona.FechaNacimiento" showIcon iconDisplay="input" 
                                                    inputId="dtpFechaNacimiento" placeholder="Fecha Nacimiento" dateFormat="dd/mm/yy" 
                                                    :class="{ 'p-invalid' : fechaNacimientoInvalido }" />
                                                <label for="lblFechaNacimiento">Fecha Nacimiento</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <FloatLabel>
                                                <Dropdown v-model="oPersona.Genero" :options="oListaGenero" 
                                                    optionLabel="Nombre" optionValue="Codigo" 
                                                    placeholder="Género" class="w-full" 
                                                    :class="{ 'p-invalid' : generoInvalido }" />
                                                <label for="lblGenero">Género</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-4">
                                            <FloatLabel>
                                                <Dropdown v-model="oPersona.EstadoCivil" :options="oListaEstadoCivil"
                                                    optionLabel="Nombre" optionValue="Codigo" 
                                                    placeholder="Estado Civil" class="w-full" 
                                                    :class="{ 'p-invalid' : estadoCivilInvalido }" />
                                                 <label for="lblEstadoCivil">Estado Civil</label>
                                            </FloatLabel>
                                        </div>

                                        <div class="field col-12 md:col-4">
                                            <FloatLabel>
                                                <InputNumber id="txtCelular" v-model="oPersona.Celular" autocomplete="off" inputId="withoutgrouping" :useGrouping="false" />
                                                <label for="lblCelular">Celular</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-8">
                                            <FloatLabel>
                                                <InputText id="txtCorreoElectronico" v-model="oPersona.Correo" autocomplete="off" />
                                                <label for="lblCorreoElectronico">Correo Electrónico</label>
                                            </FloatLabel>
                                            <p v-if="errorCorreo" style="color: red;">El correo electrónico no es válido.</p>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <Textarea id="txtDireccion" v-model="oPersona.Direccion" rows="3" cols="30" />
                                                <label for="lblDireccion">Dirección</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-6">
                                            <FloatLabel>
                                                <Textarea id="txtReferencia" v-model="oPersona.Referencia" rows="3" cols="30" />
                                                <label for="lblReferencia">Referencia</label>
                                            </FloatLabel>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="flex mt-4 justify-content-end">
                                <Button label="Guardar y Continuar" icon="pi pi-arrow-right" iconPos="right" @click="() => GuardarContinuar(nextCallback)" :loading="bCargando" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Condiciones Médicas">
                        <template #content="{ prevCallback, nextCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto justify-content-center align-items-center font-medium">
                            
                                    <div class="p-fluid p-formgrid grid m-5">
                                        <div class="field col-12 md:col-12">
                                            <h5>Número de Hijos</h5>
                                        </div>
                                                                                
                                        <div class="field col-12 md:col-2">
                                            <FloatLabel>
                                                <InputText id="txtPacienteId" v-model="oPaciente.PacienteId" type="hidden" />
                                                <InputText v-model="oPaciente.NumeroPartos" type="number" min="0" max="10"/>
                                                <label for="lblPrimerNombre">Números de Partos</label>
                                            </FloatLabel>
                                        </div>
                                        <div class="field col-12 md:col-2">
                                            <Checkbox id="chkEstado" v-model="oPaciente.TipoNatural" :binary="true" />
                                            <label for="lblEstado" class="ml-2 label-check"> Natural </label>
                                        </div>
                                        <div class="field col-12 md:col-2">
                                            <Checkbox id="chkEstado" v-model="oPaciente.TipoCesarea" :binary="true" />
                                            <label for="lblEstado" class="ml-2 label-check"> Cesárea </label>
                                        </div>

                                        <div class="field col-12 md:col-6"></div>

                                        <div class="field col-12 md:col-12">
                                            <h5>Costumbres alimentarias</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-2">
                                                    <RadioButton v-model="radCostumbresAlimentarias" inputId="radSaludable" name="costumbres" value="Saludable" />
                                                    <label for="lblSaludable" class="ml-2 label-check">Saludable</label>
                                                </div>

                                                <div class="field col-12 md:col-2">
                                                    <RadioButton v-model="radCostumbresAlimentarias" inputId="radNormal" name="costumbres" value="Normal" />
                                                    <label for="lblNormal" class="ml-2 label-check">Normal</label>
                                                </div>
                                                
                                                <div class="field col-12 md:col-2">
                                                    <RadioButton v-model="radCostumbresAlimentarias" inputId="radExcesos" name="costumbres" value="Excesos" />
                                                    <label for="lblExcesos" class="ml-2 label-check">Excesos</label>
                                                </div>

                                                <div class="field col-12 md:col-2">
                                                    <RadioButton v-model="radCostumbresAlimentarias" inputId="radOtros" name="costumbres" value="Otros" />
                                                    <label for="lblOtros" class="ml-2 label-check">Otros</label>
                                                </div>

                                                <template v-if="radCostumbresAlimentarias == 'Otros'">
                                                    <div class="field col-12 md:col-4">
                                                        <InputText id="txtCostumbresAlimentarias" v-model="txtCostumbresAlimentarias" autocomplete="off" placeholder="Otras costumbres alimentarias" />
                                                    </div>                                                    
                                                </template>
                                            </div>
                                            
                                        </div>

                                        <div class="field col-12 md:col-4">
                                            <h5>Modo de vida</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-6">
                                                    <RadioButton v-model="radModoVida" inputId="radActiva" name="ModoVida" value="A" />
                                                    <label for="lblActiva" class="ml-2 label-check">Activa</label>
                                                </div>

                                                <div class="field col-12 md:col-6">
                                                    <RadioButton v-model="radModoVida" inputId="radSedentaria" name="ModoVida" value="S" />
                                                    <label for="lblSedentaria" class="ml-2 label-check">Sedentaria</label>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider layout="vertical"  class="hidden md:flex" />             
                                        <div class="field col-12 md:col-3">
                                            <h5>Calidad de sueño</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-6">
                                                    <RadioButton v-model="radCalidadSueno" inputId="radBuena" name="CalidadSueno" value="B" />
                                                    <label for="lblBuena" class="ml-2 label-check">Buena</label>
                                                </div>

                                                <div class="field col-12 md:col-6">
                                                    <RadioButton v-model="radCalidadSueno" inputId="radMala" name="CalidadSueno" value="M" />
                                                    <label for="lblMala" class="ml-2 label-check">Mala</label>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider layout="vertical"  class="hidden md:flex" />
                                        <div class="field col-12 md:col-4">
                                            <h5>Fumar</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radFumar" inputId="radSi" name="Fumar" value="Si" />
                                                    <label for="lblSi" class="ml-2 label-check">Si</label>
                                                </div>

                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radFumar" inputId="radNo" name="Fumar" value="No" />
                                                    <label for="lblNo" class="ml-2 label-check">No</label>
                                                </div>

                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radFumar" inputId="radCasual" name="Fumar" value="Casual" />
                                                    <label for="lblNo" class="ml-2 label-check">Casual</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="field col-12 md:col-4 mr-5">
                                            <h5>Deporte practicado</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-12">
                                                    <InputText id="txtDeporte" v-model="txtDeporte" autocomplete="off" placeholder="Fútbol, voley, ciclismo, etc." />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="field col-12 md:col-3">
                                            <h5>N° de veces por semana</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-10">
                                                    <InputText v-model="txtDeportePorSemana" type="number" min="0" max="100"/>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider layout="vertical"  class="hidden md:flex" />
                                        <div class="field col-12 md:col-4">
                                            <h5>Alcohol</h5>
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radAlcohol" inputId="radSiAlcohol" name="Alcohol" value="Si" />
                                                    <label for="lblSiAlcohol" class="ml-2 label-check">Si</label>
                                                </div>

                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radAlcohol" inputId="radNoAlcohol" name="Alcohol" value="No" />
                                                    <label for="lblNoAlcohol" class="ml-2 label-check">No</label>
                                                </div>

                                                <div class="field col-12 md:col-4">
                                                    <RadioButton v-model="radAlcohol" inputId="radCasualAlcohol" name="Alcohol" value="Casual" />
                                                    <label for="lblNo" class="ml-2 label-check">Casual</label>
                                                </div>
                                            </div>
                                        </div>
                                                                                
                                        <div class="field col-12 md:col-12">
                                            <h5>Condiciones Médicas</h5>
                                            <div class="card p-2">
                                                <DataView :value="oListaCondicionesMedicas">
                                                    <template #list="slotProps">
                                                        <div class="grid grid-nogutter">
                                                            <div v-for="(item, index) in slotProps.items" :key="index" class="col-12 sm:col-6 md:col-4 xl:col-6 p-1">
                                                                <div class="p-2 border-1 surface-border surface-card border-round flex flex-column" :class="{ 'border-top-1 surface-border': index !== 0 }">
                                                                    <div class="flex flex-column md:flex-row justify-content-between md:align-items-center flex-1 gap-4">
                                                                        <div class="flex flex-row md:flex-column justify-content-between align-items-start gap-2">
                                                                            {{ item.Nombre }}
                                                                        </div>
                                                                        <div class="flex flex-column md:align-items-end gap-5">
                                                                            <div class="flex flex-row-reverse md:flex-row gap-2">
                                                                                <template v-if="item.RequiereCheck">
                                                                                    <Checkbox v-model="item.ValorCheck" :binary="true" />
                                                                                </template>
                                                                                <template v-if="item.RequiereComentario">
                                                                                    <Textarea v-model="item.Comentario" :rows="item.NumeroFilas" cols="30" />
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </template>
                                                </DataView>
                                            </div>
                                            
                                        </div>
                                        
                                    </div>

                                </div>
                            </div>
                            <div class="flex pt-4 justify-content-between">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                                <Button label="Guardar y Finalizar" icon="pi pi-arrow-right" iconPos="right" @click="() => GuardarContinuarPaciente(nextCallback)" :loading="bCargandoInicial" />
                            </div>
                        </template>
                    </StepperPanel>
                    <StepperPanel header="Fichas y Tratamientos">
                        <template #content="{ prevCallback }">
                            <div class="flex flex-column h-500rem">
                                <div class="border-2 border-dashed surface-border border-round surface-ground flex-auto flex justify-content-center align-items-center font-medium">
                                    <div class="p-fluid p-formgrid grid m-5">
                                        <div class="card">
                                            <TabView class="tabview-custom">
                                                <TabPanel>
                                                    <template #header>
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="pi pi-heart"></i>
                                                            <span class="font-bold white-space-nowrap">Fichas de Atención</span>
                                                        </div>
                                                    </template>
                                                    <Toolbar class="mb-4 p-0" style="background-color: white; border: none;">
                                                        <template #start>
                                                            <Button label="Nuevo" icon="pi pi-plus" class="p-button-success mr-2" @click="NuevaFicha" />
                                                        </template>
                                                    </Toolbar>
                                                    <div class="m-0">
                                                        <DataTable 
                                                            :value="oListaFichas" 
                                                            :rowHover="true" 
                                                            :loading="bCargando"
                                                            class="p-datatable-sm"
                                                        >
                                                            <Column field="CodigoFichaAtencion" header="Código"></Column>
                                                            <Column field="NombreProducto" header="Paquete"></Column>
                                                            <Column field="NombreCategoria" header="Categoria"></Column>
                                                            <Column field="PrecioFinal" header="Precio">
                                                                <template #body="slotProps">
                                                                    S/.{{ Utilitario.formatearDecimal(slotProps.data.PrecioFinal) }}
                                                                </template>
                                                            </Column>
                                                            <Column field="FechaInicio" header="Fecha Inicio">
                                                                <template #body="slotProps">
                                                                    {{ Utilitario.formatoFecha(slotProps.data.FechaInicio, "dd/MM/yyyy") }}
                                                                </template>
                                                            </Column>
                                                            <Column field="FechaFin" header="Fecha Fin">
                                                                <template #body="slotProps">
                                                                    {{ Utilitario.formatoFecha(slotProps.data.FechaFin, "dd/MM/yyyy") }}
                                                                </template>
                                                            </Column>
                                                            <Column field="NombreEstado" header="Estado">
                                                                <template #body="slotProps">
                                                                    <div class="centered-content">
                                                                        <Tag v-if="slotProps.data.EstadoFichaId == 3" :value="slotProps.data.NombreEstado" severity="success" />
                                                                        <Tag v-if="slotProps.data.EstadoFichaId == 2" :value="slotProps.data.NombreEstado" severity="info" />
                                                                        <Tag v-if="slotProps.data.EstadoFichaId == 1" :value="slotProps.data.NombreEstado" severity="secondary" />
                                                                    </div>
                                                                </template>
                                                            </Column>
                                                            <Column style="text-align: center;" headerStyle="min-width:12rem;">
                                                                <template #body="slotProps">
                                                                    <Button icon="pi pi-folder-open" v-tooltip.top="'Ver Ficha de Atención'" class="p-button-rounded mr-2 p-button-success" outlined 
                                                                        @click="AbrirFicha(slotProps.data.PacienteId, slotProps.data.ProductoId, slotProps.data.FichaAtencionId)" />
                                                                    <Button icon="pi pi-calendar-plus" v-tooltip.top="'Citas'" class="p-button-rounded mr-2 p-button-info" outlined 
                                                                        @click="CrearCita(slotProps.data.FichaAtencionId)" />
                                                                    <Button icon="pi pi-apple" v-tooltip.top="'Dietas'" class="p-button-rounded mr-2 p-button-warning" outlined
                                                                        @click="CrearDieta(slotProps.data.FichaAtencionId)" />
                                                                </template>
                                                            </Column>
                                                        </DataTable>
                                                    </div>
                                                    
                                                </TabPanel>
                                                <TabPanel>
                                                    <template #header>
                                                        <div class="flex align-items-center gap-2">
                                                            <i class="pi pi-history"></i>
                                                            <span class="font-bold white-space-nowrap">Historial</span>
                                                        </div>
                                                    </template>
                                                    <p class="m-0">
                                                        Aquí podrás ver el historial del paciente
                                                    </p>
                                                </TabPanel>
                                            </TabView>
                                        </div>
    
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="flex pt-4 justify-content-start">
                                <Button label="Atrás" severity="secondary" icon="pi pi-arrow-left" @click="prevCallback" />
                            </div>
                        </template>
                    </StepperPanel>
                </Stepper>

            </div>
        </div>
    </div>

    <ConfirmDialog group="headless">
        <template #container="{ message, acceptCallback }">
            <div class="flex flex-column align-items-center p-5 surface-overlay border-round">
                <div class="border-circle bg-primary inline-flex justify-content-center align-items-center h-6rem w-6rem -mt-8">
                    <i class="pi pi-check text-5xl"></i>
                </div>
                <span class="font-bold text-2xl block mb-2 mt-4">{{ message.header }}</span>
                <p class="mb-0">{{ message.message }}</p>
                <div class="flex align-items-center gap-2 mt-4">
                    <Button label="Aceptar" @click="acceptCallback"></Button>
                </div>
            </div>
        </template>
    </ConfirmDialog>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useConfirm } from 'primevue/useconfirm';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import PersonaService from '@/service/PersonaService';
import { usePrimeVue } from "primevue/config";
import Utilitario from '@/utilitarios/Utilitario';
import PacienteService from '@/service/PacienteService';
import CondicionesMedicasService from '@/service/CondicionesMedicasService';
import ConstanteService from '@/service/ConstanteService';
import Crypto from '@/utilitarios/Crypto';
import FichaAtencionService from '@/service/FichaAtencion';

//#region Variables
const store = useStore();
const toast = useToast();
const confirm = useConfirm();
const route = useRoute();
const router = useRouter();

const primerNombreInvalido = ref(false);
const apellidoPaternoInvalido = ref(false);
const apellidoMaternoInvalido = ref(false);
const tipoDocumentoInvalido = ref(false);
const numeroDocumentoInvalido = ref(false);
const fechaNacimientoInvalido = ref(false);
const generoInvalido = ref(false);
const estadoCivilInvalido = ref(false);

const errorCorreo = ref(false);
const bCargando = ref(false);
const bCargandoInicial = ref(false);

const usuarioStore = JSON.parse(store.state.datauser);
const personaService = new PersonaService();
const pacienteService = new PacienteService();
const condicionesMedicasService = new CondicionesMedicasService();
const constanteService = new ConstanteService();
const fichaAtencionService = new FichaAtencionService();
const parametro = ref(null);

const oPersona = ref({
    PersonaId: 0
});
const oPaciente = ref({
    PacienteId: 0,
    NumeroPartos: 0
});

const oListaTipoDocumento = ref([]);
const oListaGenero = ref([]);
const oListaEstadoCivil = ref([]);
const oListaCondicionesMedicas = ref([]);
const oListaFichas = ref([]);
const radCostumbresAlimentarias = ref('');
const txtCostumbresAlimentarias = ref('');
const radModoVida = ref('');
const radCalidadSueno = ref('');
const radFumar = ref('');
const txtDeporte = ref('');
const txtDeportePorSemana = ref(0);
const radAlcohol = ref('');

//#endregion

//#region Eventos
onMounted(() => {
    Inicializador();
    CambiarEspanol();
});
//#endregion

//#region Metodos
const Inicializador = () => {
    parametro.value = Crypto.Desencriptar(route.params.id);
    ListarTipoDocumento();
    ListarGenero();
    ListarEstadoCivil();
    ListarCondicionesMedicas();
    if(!Utilitario.StringIsNullOrEmpty(parametro.value)){
        ObtenerPersona(parametro.value);
    }
}

const IrAtras = () => {
    router.push({ path: `/portal/paciente` });
}

const NuevaFicha = () => {
    const id = Crypto.Encriptar(oPaciente.value.PacienteId);
    router.push({ path: `/portal/fichaatencionseleccion/${encodeURIComponent(id)}` });
}

const CambiarEspanol = () => {
    const primevue = usePrimeVue();
    primevue.config.locale.dayNames = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
    primevue.config.locale.dayNamesShort = ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"];
    primevue.config.locale.dayNamesMin = ["D", "L", "M", "X", "J", "V", "S"];
    primevue.config.locale.firstDayOfWeek = 1;
    primevue.config.locale.monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
    primevue.config.locale.monthNamesShort = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    primevue.config.locale.today = "Hoy";
    primevue.config.locale.clear = "Limpiar";
    primevue.config.locale.dateFormat = "dd/mm/yy";
    primevue.config.locale.weekHeader = "Sem";
}

const ObtenerMaximoCaracteres = () => {
    if (oPersona.value.TipoDocumento) {
        const tipoDocumento = oListaTipoDocumento.value.find(doc => doc.TipoDocumentoId === oPersona.value.TipoDocumento);
        return tipoDocumento ? tipoDocumento.NumeroCaracteres : 0;
    } else {
        return 0;
    }
}

const ListarTipoDocumento = () => {
    constanteService.ListarTipoDocumentosService().then(
        response => {
            oListaTipoDocumento.value = response;
        }
    );
}

const ListarGenero = () => {
    constanteService.ListarGeneroService().then(
        response => {
            oListaGenero.value = response;
        }
    );
}

const ListarEstadoCivil = () => {
    constanteService.ListarEstadoCivilService().then(
        response => {
            oListaEstadoCivil.value = response;
        }
    );
}

const ListarCondicionesMedicas = () => {
    condicionesMedicasService.ListarCondicionesMedicasService().then(
        response => {
            const oListaData = response.Data.map(fila => ({ ...fila, ValorCheck: false, Comentario: ''}));
            oListaCondicionesMedicas.value = oListaData;
        }
    );
}

const ObtenerPersona = (id) => {
    bCargandoInicial.value = true;
    personaService.ObtenerPersonaService(id).then(
        response => {
            const obtenerPersona = response.Data;
            oPersona.value.PersonaId = obtenerPersona.PersonaId;
            oPersona.value.Estado = obtenerPersona.Estado;
            oPersona.value.PrimerNombre = obtenerPersona.PrimerNombre;
            oPersona.value.SegundoNombre = obtenerPersona.SegundoNombre;
            oPersona.value.ApellidoPaterno = obtenerPersona.ApellidoPaterno;
            oPersona.value.ApellidoMaterno = obtenerPersona.ApellidoMaterno;
            oPersona.value.TipoDocumento = obtenerPersona.TipoDocumento.TipoDocumentoId;
            oPersona.value.NumeroDocumento = obtenerPersona.TipoDocumento.NumeroDocumento;
            oPersona.value.FechaNacimiento = Utilitario.formatoDate(obtenerPersona.FechaNacimiento);
            oPersona.value.Genero = obtenerPersona.Genero.Codigo;
            oPersona.value.EstadoCivil = obtenerPersona.EstadoCivil.Codigo;
            oPersona.value.Celular = obtenerPersona.Celular ? parseInt(obtenerPersona.Celular) : "";
            oPersona.value.Correo = obtenerPersona.Correo;
            oPersona.value.Direccion = obtenerPersona.Direccion;
            oPersona.value.Referencia = obtenerPersona.Referencia;
            ObtenerPaciente(obtenerPersona.PersonaId);
        }
    );
}

const ObtenerPaciente = (id) => {
    pacienteService.ObtenerPacientePorPersonaService(id).then(
        response => {
            const obtenerPaciente = response.Data;
            
            oPaciente.value.PacienteId = obtenerPaciente.PacienteId;
            oPaciente.value.CodigoPaciente = obtenerPaciente.CodigoPaciente;
            oPaciente.value.NumeroPartos = obtenerPaciente.NumeroPartos;
            oPaciente.value.TipoNatural = obtenerPaciente.TipoNatural;
            oPaciente.value.TipoCesarea = obtenerPaciente.TipoCesarea;
            oPaciente.value.Estado = obtenerPaciente.Estado;
            
            if(obtenerPaciente.CostumbresAlimentarias == "Saludable" || 
                obtenerPaciente.CostumbresAlimentarias == "Normal" || 
                obtenerPaciente.CostumbresAlimentarias == "Excesos"){
                    radCostumbresAlimentarias.value = obtenerPaciente.CostumbresAlimentarias;
            }
            else{
                radCostumbresAlimentarias.value = "Otros";
                txtCostumbresAlimentarias.value = obtenerPaciente.CostumbresAlimentarias;
            }
            
            radModoVida.value = obtenerPaciente.ModoVida.CodigoModoVida;
            radCalidadSueno.value = obtenerPaciente.CalidadSueno.CodigoCalidadSueno;
            radFumar.value = obtenerPaciente.Fumar.toString();
            txtDeporte.value = obtenerPaciente.DeportePracticado;
            txtDeportePorSemana.value = obtenerPaciente.DeportePracticadoSemana;
            radAlcohol.value = obtenerPaciente.Alcohol.toString();

            oListaCondicionesMedicas.value.forEach((item, index) => {
                const itemBD = obtenerPaciente.ListaCondicionesMedicas.find(condicion => condicion.CondicionesMedicasId === item.CondicionesMedicasId);
                if(itemBD) {
                    oListaCondicionesMedicas.value[index].ValorCheck = itemBD.ValorCheck;
                    oListaCondicionesMedicas.value[index].Comentario = itemBD.Comentario;
                }
            });

            ListarFichasPaciente();
            bCargandoInicial.value = false;
        }
    );
}

const ListarFichasPaciente = () => {
    const request = {
        PacienteId: oPaciente.value.PacienteId
    };

    fichaAtencionService.ListarFichasPacienteService(request).then(
        response => {
            oListaFichas.value = response.Data;
        }
    );
}

const GuardarContinuar = async (nextCallback) => {    
    if (ValidarPersona()) {
        if(await GuardarPersona()){
            nextCallback();
        }
    } else {
        toast.add({ severity: 'warn', summary: 'Validación', detail: 'Por favor complete todos los campos requeridos.', life: 3000 });
    }
}

const ValidarPersona = () => {
    const estadoValidacion = ref(true);
    const { ...model } = oPersona.value;

    if(!model?.PrimerNombre){
        primerNombreInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        primerNombreInvalido.value = false;
    }

    if(!model?.ApellidoPaterno){
        apellidoPaternoInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        apellidoPaternoInvalido.value = false;
    }

    if(!model?.ApellidoMaterno){
        apellidoMaternoInvalido.value = true;
        estadoValidacion.value  = false;
    }
    else{
        apellidoMaternoInvalido.value = false;
    }

    if(!model?.TipoDocumento){
        tipoDocumentoInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        tipoDocumentoInvalido.value = false;
    }

    if(!model?.NumeroDocumento){
        numeroDocumentoInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        const maxCaracteres = ObtenerMaximoCaracteres();
        if(model.NumeroDocumento.length < maxCaracteres){
            numeroDocumentoInvalido.value = true;
            estadoValidacion.value = false;
            toast.add({ severity: 'warn', summary: 'Advertencia', detail: 'El número de documento debe tener ' + maxCaracteres + ' carácteres.', life: 6000 });
        }
        else {
            numeroDocumentoInvalido.value = false;
        }
    }

    if(!model?.FechaNacimiento){
        fechaNacimientoInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        fechaNacimientoInvalido.value = false;
    }

    if(!model?.Genero){
        generoInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        generoInvalido.value = false;
    }

    if(!model?.EstadoCivil){
        estadoCivilInvalido.value = true;
        estadoValidacion.value = false;
    }
    else{
        estadoCivilInvalido.value = false;
    }

    return estadoValidacion.value;
}

const GuardarPersona = () => {
    return new Promise((resolve, reject) => {
        const { ...model } = oPersona.value;
        
        const request = {
            ...model,
            Foto: '',
            UsuarioCreacion: usuarioStore.User.UsuarioId
        }
        
        confirm.require({
            message: '¿Está seguro de guardar los Datos Personales?',
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {
                bCargandoInicial.value = true;
                bCargando.value = true;
                
                try{
                    const response = await personaService.RegistrarPersonaService(request);
                    
                    if(response.Status == 201) {
                        oPersona.value.PersonaId = response.Data[0].PersonaId;
                        oPersona.value.Estado = true;

                        const requestPaciente = await MapearPaciente();                        
                        const responsePaciente = await pacienteService.RegistrarPacienteService(requestPaciente);
                        if(responsePaciente.Status == 201){
                            oPaciente.value.PacienteId = responsePaciente.Data[0].PacienteId;
                            ObtenerPaciente(oPersona.value.PersonaId);
                            parametro.value = responsePaciente.Data[0].PacienteId;
                        }

                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: 'Se registraron los Datos Personales correctamente',
                            accept: () => {
                                bCargando.value = false;
                                bCargandoInicial.value = false;
                                resolve(true);
                            }
                        });
                    }
                    else{
                        bCargando.value = false;
                        bCargandoInicial.value = false;
                        toast.add({ severity: 'warn', summary: 'Validación', detail: response.Message, life: 6000});
                        resolve(false);
                    }
                } catch (error) {
                    bCargando.value = false;
                    bCargandoInicial.value = false;
                    console.error('Error al registrar el paciente:', error);
                    reject(false); 
                }
            
            }
        });

    });
}

const MapearPaciente = () => {
    const { ...modelPaciente } = oPaciente.value;
    oListaCondicionesMedicas.value.forEach(objeto => {
        if(objeto.RequiereComentario){
            objeto.ValorCheck = (objeto.Comentario.trim() !== '') ? true : false;
        }
    });
    
    const request = {
        ...modelPaciente,
        Persona: {
            PersonaId: oPersona.value.PersonaId
        },
        NumeroHijos: 0,
        Establecimiento : {
            EstablecimientoId: usuarioStore.User.EstablecimientoId
        },
        CostumbresAlimentarias: radCostumbresAlimentarias.value == 'Otros' ? txtCostumbresAlimentarias.value : radCostumbresAlimentarias.value,
        ModoVida: radModoVida.value,
        CalidadSueno: radCalidadSueno.value,
        Fumar: radFumar.value,
        DeportePracticado: txtDeporte.value,
        DeportePracticadoSemana: txtDeportePorSemana.value,
        Alcohol: radAlcohol.value,
        UsuarioCreacion: usuarioStore.User.UsuarioId,
        ListaPacienteCondicionesMedicas: oListaCondicionesMedicas.value.filter(x => x.ValorCheck)
    }
    
    return request;
}

const GuardarContinuarPaciente = async(nextCallback) => {
    if(await GuardarPaciente()){
        nextCallback();
    }
}

const GuardarPaciente = () => {
    return new Promise((resolve, reject) => {
        const request = MapearPaciente();

        confirm.require({
            message: 'Está seguro de guardar las Condiciones Médicas',
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            rejectLabel: 'No',
            acceptLabel: 'Si',
            accept: async () => {                
                try{
                    bCargandoInicial.value = true;
                    const response = await pacienteService.RegistrarPacienteService(request);                    
                    if(response.Status == 201){
                        
                        oPaciente.value.PacienteId = response.Data[0].PacienteId;
                        oPaciente.value.CodigoPaciente = response.Data[0].CodigoPeciente;
                        parametro.value = response.Data[0].PacienteId;

                        confirm.require({
                            group: 'headless',
                            header: 'Felicidades',
                            message: 'Se guardaron las Condiciones Médicas Correctamente',
                            accept: () => {
                                bCargandoInicial.value = false;
                                resolve(true);
                            }
                        });
                    }
                    else{
                        bCargandoInicial.value = false;
                        toast.add({ severity: 'error', summary: 'Error', detail: response.Message, life: 3000});
                        reject(false);
                    }
                } catch (error) {
                    bCargandoInicial.value = false;
                    console.error('Error al registrar el paciente:', error);
                    reject(false); 
                }
                
            }
        });
    });
}

const AbrirFicha = (pacienteId, productoId, fichaId) => {
    const pacienteIdUrl = encodeURIComponent(Crypto.Encriptar(pacienteId));
    const productoIdUrl = encodeURIComponent(Crypto.Encriptar(productoId));
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/fichaatencion/${pacienteIdUrl}/${productoIdUrl}/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}

const CrearCita = (fichaId) => {
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/citaatencion/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}

const CrearDieta = (fichaId) => {
    const fichaIdUrl = encodeURIComponent(Crypto.Encriptar(fichaId));
    let route = router.resolve({ path: `/portal/dietaatencion/${fichaIdUrl}` });
    window.open(route.href, '_blank');
}
//#endregion

//#region Eventos 

//#endregion

</script>

<style scoped lang="scss">
.label-check{
    margin-top: 7px;
    margin-bottom: 0px;
}

.progress-spinner-custom {
    position: absolute;
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.53);
    margin-left: -28px;
    margin-top: -28px;
    border-radius: 12px;
}

.spinner {
    position: relative; /* Cambiado a relativo */
    height: 100%; /* Ajusta según sea necesario */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card {
  position: relative;
  /* Asegúrate de que la tarjeta tenga una posición relativa */
}
/*.progress-spinner-custom {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

// Transparent Overlay 
.progress-spinner-custom:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.53);
}*/
</style>
